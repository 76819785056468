import './team.css';
import { useTranslation } from 'react-i18next';

const TeamSection = () => {
  const { t } = useTranslation();

  return (
    <section className="team-section">
      <div className="team-container">
        <h2>{t('team-title')}</h2>
        <p className="team-intro">{t('team-intro')}</p>
        
        <div className="team-member">
          <div className="team-member-photo">
            <img src="/Mehdi-Portrait-Pro.jpg" alt="Mehdi Jafari" />
          </div>
          <div className="team-member-bio">
            <div dangerouslySetInnerHTML={{ __html: t('team-mehdi-bio') }} />
          </div>
        </div>

        <div className="team-member">
          <div className="team-member-photo">
            <img src="/Marie-Helene-Portrait-Pro.jpg" alt="Marie-Hélène" />
          </div>
          <div className="team-member-bio">
            <div dangerouslySetInnerHTML={{ __html: t('team-marie-bio') }} />
          </div>
        </div>

        <div className="team-ecosystem">
          <div dangerouslySetInnerHTML={{ __html: t('team-ecosystem') }} />
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
