import './info.css';

import { useTranslation } from 'react-i18next';

function InfoSection() {
  const { t } = useTranslation();

  return (
    <div className="info-section">
      <h2 className="info-title">{t('cloud_challenges_title')}</h2>
      <div className="info-content">
        <div className="info-text">
          <p dangerouslySetInnerHTML={{ __html: t('cloud_challenges_part_1') }} />
          <p dangerouslySetInnerHTML={{ __html: t('cloud_challenges_part_2') }} />
          <p dangerouslySetInnerHTML={{ __html: t('cloud_challenges_part_3') }} />
        </div>
        <div className="info-image">
          <img src="/cloud-challenges-v3.jpeg" alt="Cloud Cost Challenges" />
        </div>
      </div>
    </div>
  );
}

export default InfoSection;
