import { FaLinkedin } from 'react-icons/fa';
import './footer.css';

const FooterSection = () => {
  return (
    <footer className="footer-section">
      <hr className="footer-line" />
      <div className="footer-content">
        <p>© 2025 Cloud Solution Provider Inc.</p>
        <a
          title='CloudSibyl LinkedIn'
          href="https://www.linkedin.com/company/cloudsibyl/"
          target="_blank"
          rel="noopener noreferrer"
          className="linkedin-link"
        >
          <FaLinkedin size={30} />
        </a>
      </div>
    </footer>
  );
};

export default FooterSection;
